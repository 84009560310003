.Mui-disabled {
  color: #FFF !important;
  -webkit-text-fill-color: #fff !important;
}
::-webkit-scrollbar {
  width: 1em;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: #8A7B7B;
  outline: 1px solid slategrey;
  border-radius: 10px;
}
 
/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Meta Pro', sans-serif;
    background-color: #111;
    color: #fff;
}


.terminal-section {
    display: flex;
    justify-content: center;
    padding: 60px 20px;
}

.terminal {
    background-color: #000;
    border-radius: 10px;
    width: 50%;
    padding: 20px;
}

.terminal-header {
    display: flex;
    justify-content: space-between;
    background-color: #333;
    padding: 5px 15px;
    border-radius: 10px 10px 0 0;
}

.buttons span {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
}

.red { background-color: #ff605c; }
.yellow { background-color: #ffbd44; }
.green { background-color: #00ca4e; }

.terminal-title {
    color: #fff;
}

.terminal-body {
    padding: 20px;
    max-height: 100%;
    overflow-y: auto;
}

#chat-output {
    font-size: 16px;
}

#chat-input {
    width: 100%;
    padding: 10px;
    background-color: #222;
    color: #fff;
    border: none;
    font-family: 'Courier New', Courier, monospace;
}

footer {
    background-color: #222;
    text-align: center;
    padding: 20px;
    color: #fff;
}

@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }

@media only screen and (max-device-width: 640px) {
  .terminal {
      position: absolute;
      width:100%;
  }
}
